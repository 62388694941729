import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import RoomService from '../../services/room-service';


export default function MediaUploadButton(props) {
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadTotal, setUploadTotal] = useState(0);

	const VALID_TYPES = "image/*, .mp4, .dcm, .dicom, .gltf, .glb, .stl, .pdf"

	function onUploadProgress(progress) {
		if (progress.loaded < progress.total) {
			setIsUploading(true);
			setUploadTotal(progress.total);
			setUploadProgress(progress.loaded);
		} else {
			// upload done
			setIsUploading(false);
			setUploadTotal(0);
			setUploadProgress(0);
		}
	}

	function uploadChange(event) {
		const formData = new FormData();
		const files = event.target.files;

		for (const file of files) {
			formData.append("files", file);
		}

		RoomService.uploadFiles(props.room_id, formData, onUploadProgress).then((res) => {
			if (props.onUploadCallback) {
				props.onUploadCallback(res.data);
			}
		}).catch((err) => {
			alert(`Error when uploading files: ${err}`);
		});
	};

	function calcProgress() {
		if (uploadProgress == 0) return 0;
		return Math.round((uploadProgress / uploadTotal) * 100);
	}

	return (<>
		{isUploading ? <button className="button" disabled>
			<progress
				className="progress is-info"
				value={uploadProgress}
				max={uploadTotal}
			></progress>{calcProgress()}%
		</button> : <div className="file is-centered">
			<label className="file-label">
				<input
					className="file-input"
					type="file"
					id="files"
					name="files"
					accept={VALID_TYPES}
					multiple={true}
					onChange={uploadChange}
				/>
				<span className="file-cta">
					<span className="file-icon">
						<FontAwesomeIcon icon={faUpload} />
					</span>
					<span className="file-label">Upload File(s)</span>
				</span>
			</label>
		</div>}
	</>);
}
