import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faFilePdf, faCube, faTrash, faChevronRight } from '@fortawesome/free-solid-svg-icons';


function getMediaUrl(room_id, file_id) {
	return `${process.env.REACT_APP_LUMYS_API_URL}room/${room_id}/files/${file_id}`;
}

function getMediaPreviewUrl(type, room_id, file_id) {
	switch (type) {
		case 'model': return `${window.location.origin}/room/${room_id}/edit/preview/${file_id}`;
		case 'dicom': return `${process.env.REACT_APP_LUMYS_API_URL}room/${room_id}/files/${file_id}/png`;
		default: return getMediaUrl(room_id, file_id);
	}
}

function getMediaThumbnailElement(type, room_id, file_id) {
	switch (type) {
		case 'model': return <p className="image is-64x64"><FontAwesomeIcon icon={faCube} size="3x" /></p>;
		case 'video': return <img className="image is-64x64" src={`${getMediaUrl(room_id, file_id)}/thumbnail`} />;
		case 'image': return <img className="image is-64x64" src={`${getMediaUrl(room_id, file_id)}`} />;
		case 'pdf': return <p className="image is-64x64"><FontAwesomeIcon icon={faFilePdf} size="3x" /></p>;
		case 'dicom': return <img className="image is-64x64" src={`${getMediaUrl(room_id, file_id)}/png`} />;
		case 'other': return <p className="image is-64x64"><FontAwesomeIcon icon={faQuestion} size="3x" /></p>;
	}
}


export function MediaTableItem({type, room_id, file, onDeleteCallback}) {
	return (<tr>
		<td>
			<a href={getMediaPreviewUrl(type, room_id, file.id)} target="_blank">
				{getMediaThumbnailElement(type, room_id, file.id)}
			</a>
		</td>
		<td>{file.name}</td>
		<td>{file.mimetype}</td>
		<td>
			<button className="button is-small is-danger" onClick={() => {
					if (onDeleteCallback) onDeleteCallback(file.id)}
			}>
				<FontAwesomeIcon icon={faTrash} />
			</button>
		</td>
	</tr>);
}


export function MediaListItem({type, room_id, file, onClickPreview, onClickSelectMedia}) {
	return (<div className="list has-visible-pointer-controls has-hoverable-list-items">
		<div className="list-item" onClick={() => { if (onClickPreview) onClickPreview(file); }}>
			<div className="list-item-image">{getMediaThumbnailElement(type, room_id, file.id)}</div>
			<div className="list-item-content">
				<div className="list-item-title has-text-left">{file.name}</div>
			</div>
			<div className="list-item-controls">
				<div className="buttons is-right">
				{type === 'other' ?
					<button className="button is-rounded" disabled>
						<span className="icon">
							<FontAwesomeIcon icon={faChevronRight} />
						</span>
					</button>
				: (type === 'image' || type === 'video' || type === 'dicom') ?
					<button
						className="button is-success is-rounded"
						onClick={() => { if (onClickSelectMedia) onClickSelectMedia(file.id); }}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faChevronRight} />
						</span>
					</button>
				: <a href={`${getMediaUrl(room_id, file.id)}`} target="_blank"
						className="button is-info is-rounded"
					>
						<span className="icon">
							<FontAwesomeIcon icon={faChevronRight} />
						</span>
					</a>}
				</div>
			</div>
		</div>
	</div>);
}
